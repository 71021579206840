<template>
  <v-expansion-panels :readonly="disabled" hover flat no-gutters :value="renderOpen">
      <v-expansion-panel @change="onChange">
        <v-expansion-panel-header class="expansion-header" :hide-actions="false">
            <div :class="`header-text${$vuetify.breakpoint.xs ? ' mobile' : ''}`">
              <div>
                <strong>{{headerBold}}</strong><span> {{headerNormal}}</span>
              </div>
              <div>
                <v-icon v-if="completed" class="completed-icon" color="#fff">
                    mdi-check-bold
                </v-icon>
              </div>
            </div>
            <template v-slot:actions>
              <v-icon :class="`expansion-icon${$vuetify.breakpoint.xs ? ' mobile' : ''}${disabled ? ' disabled' : ''}`">
                {{disabled ? 'mdi-lock' : '$expand'}}
              </v-icon>
            </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content eager class="expansion-content">
            <slot></slot>
        </v-expansion-panel-content>
      </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
  export default {
    name: 'ExpansionPanel',
    props: {
      disabled: {
        type: Boolean,
        required: false,
      },
      headerBold: {
        type: String,
        required: false
      },
      headerNormal: {
        type: String,
        required: false
      },
      completed: {
        type: Boolean,
        required: false,
        default: false,
      },
      onClose: {
        type: Function,
        required: false
      },
      onOpen: {
        type: Function,
        required: false,
      },
      open: {
        type: Boolean,
        required: false,
      }
    },
    watch: { 
      open: function(newVal) {
        if (newVal === false && this.onClose) {
          this.onClose(true)
        }
        this.isOpen = newVal
      }
    },
    data () {
      return {
        isOpen: null,
      }
    },
    methods: {
      onChange () {
        this.isOpen = !this.isOpen
        if (!this.isOpen && this.onClose) {
          this.onClose()
        } else if (this.isOpen && this.onOpen) {
          this.onOpen()
        }
      }
    },
    computed: {
      renderOpen () {
        if (this.open !== undefined) {
          return this.open ? 0 : null
        } else {
          return this.isOpen ? 0 : null
        }
      }
    }
  }
</script>

<style lang="less" scoped>
  @import './ExpansionPanel.less';
</style>
