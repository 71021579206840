<template>
  <v-container fluid class="pl-10 pr-10 ma-0 payment-container">
    <v-row class="text-center" no-gutters v-if="!Object.keys($store.state.merchantData).length">
      <v-col>
        Loading...
      </v-col>
    </v-row>

    <v-row class="text-center" no-gutters v-if="Object.keys($store.state.merchantData).length">
      <v-col :class="`${$vuetify.breakpoint.xs ? 'mobile-panels' : ''}`" sm=6 md=8>
        <v-row>
          <v-col cols=12 md=6>
            <v-form ref="amount" v-model="isAmountValid">
              <AmountInput v-model="formFields.amount" :rules="[rules.required, rules.amountAboveZero]"></AmountInput>
            </v-form>
          </v-col>
          <v-col cols=12 md=6 class="cashdiscount-description" v-if="$store.state.merchantData.cashdiscount_schedule && !$store.state.paymentSuccessful">
            <span v-if="paymentMethod !== 'ach'">There will be a <strong class="summary--text-item__black">{{feeSchedule[$store.state.merchantData.cashdiscount_schedule][10000000000].amount}}% fee</strong> added to any card payments. <span v-if="paymentMethod !== 'ach' && $store.state.merchantData.payment_types.includes('ach')">This fee is waived when paying by ACH.</span></span>
            <span v-else>Pay through your checking account.</span>
          </v-col>
        </v-row>

        <div class="expansion-panels">
          <v-form ref="customerInfo" v-model="isCustomerInfoValid"> 
            <ExpansionPanel
              :disabled="$store.state.paymentSuccessful"
              :open="openPanel === 'customer'" 
              headerBold="Customer"
              headerNormal="Information"
              :completed="isCustomerInfoValid"
              :onOpen="() => setOpenPanel('customer')"
            >
              <div class="expansion-panel-content">
                <v-row>
                  <v-col class="py-0 py-sm-3" md=6>
                    <v-text-field
                      v-model="formFields.customerName"
                      :rules="[rules.required]"
                    >
                      <template v-slot:label>
                        <span class="form-label">Customer Name <span class="form-label__blue">*</span></span>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col class="py-0 py-sm-3" md=6>
                    <v-text-field v-model="formFields.customFieldValue">
                      <template v-slot:label>
                        <span class="form-label">Paid on Behalf of (optional)</span>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="py-0 py-sm-3">
                    <v-text-field v-model="formFields.accountNumber">
                      <template v-slot:label>
                        <span class="form-label">Account Number</span>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
              </div>
            </ExpansionPanel>
          </v-form>

          <v-form ref="paymentDetails" v-model="isPaymentDetailsValid">
            <ExpansionPanel
              :disabled="$store.state.paymentSuccessful"
              :open="openPanel === 'payment'"
              headerBold="Payment"
              headerNormal="Details"
              :completed="isPaymentDetailsValid" 
              :onOpen="() => setOpenPanel('payment')">
              <div class="expansion-panel-content">
                <v-row>
                  <v-col cols="6" md="6" v-if="$store.state.merchantData.payment_types.includes('card')">
                    <v-tooltip bottom :disabled="!$store.state.merchantData.cashdiscount_schedule">
                      <template v-slot:activator="{on, attrs}">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          block
                          :depressed="paymentMethod === 'card'"
                          :outlined="paymentMethod !== 'card'"
                          color="var(--blueText)"
                          :class="`payment-method-btn${paymentMethod === 'card' ? ' white--text' : ''}${$vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? ' mobile' : ''}`"
                          :x-large="!$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm"
                          :small="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
                          @click="setMethod('card')"
                        >
                          <span :class="`${$vuetify.breakpoint.xs ? 'mobile-pay-by' : ''}`">PAY BY CARD</span>
                        </v-btn>
                      </template>
                      <span v-if="$store.state.merchantData.cashdiscount_schedule">A Price Adjusment fee of {{feeSchedule[$store.state.merchantData.cashdiscount_schedule][10000000000].amount}}% will be applied</span>
                    </v-tooltip>
                  </v-col>

                  <v-col cols="6" md=6 v-if="$store.state.merchantData.payment_types.includes('ach')">
                    <v-btn
                      block
                      :depressed="paymentMethod === 'ach'"
                      :outlined="paymentMethod !== 'ach'"
                      color="var(--blueText)"
                      :x-large="!$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm"
                      :small="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
                      :class="`payment-method-btn${paymentMethod === 'ach' ? ' white--text' : ''}${$vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? ' mobile' : ''}`"
                      @click="setMethod('ach')"
                    >
                      <span :class="`${$vuetify.breakpoint.xs ? 'mobile-pay-by' : ''}`">PAY BY ACH</span>
                    </v-btn>
                  </v-col>
                </v-row>

                <div class="card-fields" v-if="paymentMethod === 'card'">
                  <v-row>
                    <v-col class="pb-0 py-sm-3" md=6>
                      <v-text-field
                        :rules="[rules.required]"
                        v-model="formFields.cardNumber"
                        v-mask="cardMask()"
                        :key="cardNumberInputKey"
                        :maxLength="getCardMaxLength()"
                      > 
                        <template v-slot:label>
                          <span class="form-label">Card Number <span class="form-label__blue">*</span></span>
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col class="pb-0 py-sm-3" md=6>
                      <v-text-field v-model="formFields.customerName" :rules="[rules.required]">
                        <template v-slot:label>
                          <span class="form-label">Cardholder Name <span class="form-label__blue">*</span></span>
                        </template>
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="py-0 py-sm-3" md=6>
                      <v-text-field
                        :rules="[rules.required, rules.expirationMinLength, rules.expirationInFuture]"
                        v-model="formFields.cardExpiration"
                        v-mask="'##/##'"
                        :key="expirationInputKey"
                        ref="expiration"
                      >
                        <template v-slot:label>
                          <span class="form-label">Expiration Date (MM/YY) <span class="form-label__blue">*</span></span>
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col class="d-none"></v-col>
                    <v-col class="py-0 py-sm-3" md=6>
                      <v-text-field
                        aria-autocomplete="off"
                        maxLength=4
                        v-model="formFields.cardCVV"
                        v-mask="'####'"
                        :rules="[rules.required]"
                      >
                        <template v-slot:label>
                          <span class="form-label">AVS/CVV <span class="form-label__blue">*</span></span>
                        </template>
                      </v-text-field>
                    </v-col>
                  </v-row>
                </div>

                <div class="ach-fields" v-if="paymentMethod === 'ach'">
                  <v-row>
                    <v-col class="py-0 py-sm-3" md=6>
                      <v-text-field
                        :messages="['Where can I find this?']"
                        v-model="formFields.achRoutingNumber"
                        :rules="[rules.required, rules.routingLength]"
                      >
                        <template v-slot:message>
                          <a class="ach-hint" v-on:click.prevent.stop="toggleModal">Where can I find this?</a>
                        </template>
                        <template v-slot:label>
                          <span class="form-label">Routing Number <span class="form-label__blue">*</span></span>
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col class="py-0 py-sm-3" md=6>
                      <v-text-field
                        :messages="['Where can I find this?']"
                        v-model="formFields.achAccountNumber"
                        :rules="[rules.required]"
                      >
                        <template v-slot:message>
                          <a class="ach-hint" v-on:click.prevent.stop="toggleModal">Where can I find this?</a>
                        </template>
                        <template v-slot:label>
                          <span class="form-label">Account Number <span class="form-label__blue">*</span></span>
                        </template>
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="reduced-bottom">
                    <v-col class="py-0 py-sm-3" md=6>
                      <v-select
                        v-model="formFields.achAccountType"
                        :items="['Checking', 'Savings', 'Business Checking']"
                      >
                        <template v-slot:label>
                          <span class="form-label">Account Type <span class="form-label__blue">*</span></span>
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </ExpansionPanel>
          </v-form>

          <v-form ref="billingInfo" v-model="isBillingInfoValid">                
            <ExpansionPanel
              :disabled="$store.state.paymentSuccessful"
              headerBold="Billing"
              headerNormal="Information"
              :open="openPanel === 'billing'"
              :completed="isBillingInfoValid"
              :onOpen="() => setOpenPanel('billing')"
            >
              <div class="expansion-panel-content">
                <v-row>
                  <v-col class="py-0 py-sm-3" md=6>
                    <v-text-field v-model="formFields.customerName" :rules="[rules.required]">
                      <template v-slot:label style="max-width: 100%; width: 100%;">
                        <span class="form-label">Customer/Cardholder Name <span class="form-label__blue">*</span></span>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col class="py-0 py-sm-3" md=6>
                    <v-text-field
                      v-model="formFields.billingPhone"
                      v-mask="'(###) ###-####'"
                      :maxLength="14"
                    >
                      <template v-slot:label>
                        <span class="form-label">Phone</span>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="py-0 py-sm-3" md=6>
                    <v-text-field v-model="formFields.billingAddress1" :rules="[rules.required]"> 
                      <template v-slot:label>
                        <span class="form-label">Address <span class="form-label__blue">*</span></span>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col class="py-0 py-sm-3" md=6>
                    <v-text-field v-model="formFields.billingAddress2">
                      <template v-slot:label>
                        <span class="form-label">Address Line 2</span>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols=6 class="py-0 py-sm-3" sm=6>
                    <v-text-field v-model="formFields.billingCity" :rules="[rules.required]"> 
                      <template v-slot:label>
                        <span class="form-label">City <span class="form-label__blue">*</span></span>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols=6 class="py-0 py-sm-3" sm=6 md=3>
                    <StateField
                      v-model="formFields.billingState"
                      :required="true"
                      :rules="[rules.required]"
                      :textfield="formFields.billingCountry !== 'USA'"
                    ></StateField>

                  </v-col>
                  <v-col cols=6 class="py-0 py-sm-3" sm=6 md=3>
                    <v-text-field
                      v-model="formFields.billingZip"
                      :rules="[rules.required]"
                      maxLength=11
                    >
                      <template v-slot:label>
                        <span class="form-label">Zip <span class="form-label__blue">*</span></span>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col class="py-0 py-sm-3" cols=6 v-if="paymentMethod === 'card'" sm=6>
                    <CountryField
                      v-model="formFields.billingCountry"
                      :disabled="paymentMethod !== 'card'"
                      @input="(country) => {
                        this.formFields.billingCountry = country
                      }"
                      :required="true"
                      :rules="[rules.required]"
                    ></CountryField>
                  </v-col>
                  <v-col class="py-0 py-sm-3" cols=6 sm=6> 
                    <v-text-field
                      v-model="formFields.billingEmail"
                      :rules="[rules.required, rules.isValidEmail]"
                    >
                      <template v-slot:label>
                        <span class="form-label">Email <span class="form-label__blue">*</span></span>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col class="d-none d-md-flex" sm=6 v-if="paymentMethod === 'card'"></v-col>
                  <v-col class="py-0 py-sm-3" sm=12 md=6>
                    <ACHAuthCheckbox v-if="paymentMethod === 'ach'" v-model="formFields.achAuthorized" :onReadModalClick="() => setShowACHAuthModal(true)"></ACHAuthCheckbox>
                  </v-col>
                 <v-col class="py-0 py-sm-3" sm=9></v-col>
                </v-row>
              </div>
            </ExpansionPanel>

            <div class="d-sm-none" v-if="renderPayNowButton">
              <PaymentButton
                :disabled="$store.state.paymentProcessing || !(isCustomerInfoValid && isPaymentDetailsValid && isBillingInfoValid  && (paymentMethod !== 'ach' || formFields.achAuthorized))"
                :onClick="toggleSummary"
              >Verify Payment</PaymentButton>
            </div>

          </v-form>
        </div>

        <div class="loading-box" v-if="$store.state.paymentProcessing || $store.state.paymentSuccessful !== null">
          <ProcessingBox :resetForm="resetForm" :setPanel="setOpenPanel" :paymentMethod="paymentMethod"></ProcessingBox>
        </div>
      </v-col>

      <v-spacer></v-spacer>

      <v-col xs=12 sm=4 md=3 class="d-none d-sm-block summary">
        <div class="summary--text">Summary</div>
        <div class="summary--text-item" v-if="formFields.amount">Amount: <span class="summary--text-item__black">{{totalAmountFormatted}}</span></div>
        <div class="summary--text-item" v-if="formFields.customerName">Customer Name: <span class="summary--text-item__blue">{{formFields.customerName}}</span></div>
        <div class="summary--text-item" v-if="formFields.customFieldValue">Paid on Behalf of: <span class="summary--text-item__blue">{{formFields.customFieldValue}}</span></div>
        <div class="summary--text-item" v-if="formFields.accountNumber">Account #: <span class="summary--text-item__blue">{{formFields.accountNumber}}</span></div>
        <div class="summary--text-item" v-if="paymentMethod">Payment: <strong class="summary--text-item__black">{{getPaymentType()}}</strong></div>
        <div class="summary--text-item" v-if="paymentMethod === 'ach' && formFields.achRoutingNumber">Routing Number: <span class="summary--text-item__blue">{{formFields.achRoutingNumber}}</span></div>
        <div class="summary--text-item" v-if="paymentMethod === 'ach' && formFields.achAccountNumber">Account Number: <span class="summary--text-item__blue">{{formFields.achAccountNumber.slice(0,-4).replaceAll(/([0-9])/gm, '•')}}{{formFields.achAccountNumber.slice(-4)}}</span></div>
        <div class="summary--text-item" v-if="paymentMethod === 'ach' && formFields.achAccountType">Account Type: <span class="summary--text-item__blue">{{formFields.achAccountType}}</span></div>
        <div class="summary--text-item" v-if="paymentMethod === 'card' && formFields.cardNumber">Card Number: <span :class="$store.state.paymentSuccessful === false ? 'summary--text-item__red' : 'summary--text-item__blue'">{{formattedCard.slice(0, -4).replaceAll(/([0-9])/gm, '•')}}{{formattedCard.slice(-4)}}</span></div>
        <v-row v-if="formFields.billingAddress1 || formFields.billingAddress2 || formFields.billingPhone || formFields.billingCity || formFields.customerName || formFields.billingState || formFields.billingZip">
          <v-col md=2>
            <div class="summary--text-item">Billing:</div>
          </v-col>
          <v-col md=8>
            <div class="summary--text-item__blue billing-summary" v-if="formFields.customerName">{{formFields.customerName}}</div>
            <div class="summary--text-item__blue billing-summary" v-if="formFields.billingAddress1">{{formFields.billingAddress1}}</div>
            <div class="summary--text-item__blue billing-summary" v-if="formFields.billingAddress2">{{formFields.billingAddress2}}</div>
            <div class="summary--text-item__blue billing-summary" v-if="formFields.billingCity">{{formFields.billingCity}}</div>
            <div class="summary--text-item__blue billing-summary" v-if="formFields.billingState || formFields.billingZip">{{formFields.billingState}}{{formFields.billingState && formFields.billingZip ? ' ' : ''}}{{formFields.billingZip}}</div>
          </v-col>
        </v-row>
        <div class="summary-dialog--card--summary--text-item billing-email" v-if="formFields.billingEmail"><div>Email:</div><div class="summary-dialog--card--summary--text-item__blue email">{{formFields.billingEmail}}</div></div>

        <div class="summary--text-item" v-if="renderPayNowButton && $store.state.merchantData.custom.summaryFooter">
          <template v-if="Array.isArray($store.state.merchantData.custom.summaryFooter)">
            <v-col v-for="(footer, index) in $store.state.merchantData.custom.summaryFooter" :key="index" class="ml-0 pl-0" cols="12" justify="center">
              <div v-html="footer"></div>
            </v-col>
          </template>
          <template v-else>
            <v-col class="ml-0 pl-0" cols="12" justify="center">
              <div v-html="$store.state.merchantData.custom.summaryFooter"></div>
            </v-col>
          </template>
        </div>

        <div v-if="renderPayNowButton" class="pay-now-container">
          <PaymentButton :disabled="paymentProcessing || !(isCustomerInfoValid && isPaymentDetailsValid && isBillingInfoValid)" :loading="$store.state.isProcessingPayment" :onClick="sendPayment">Pay Now</PaymentButton>
        </div>

        <div v-if="$store.state.paymentSuccessful !== null" class="response-message">
          <hr>
          <div class="summary--text-item">Status: <span :class="{'summary--text-item__blue': $store.state.paymentSuccessful, 'summary--text-item__red': !$store.state.paymentSuccessful}">{{$store.state.paymentSuccessful ? 'APPROVED' : 'DECLINED'}}</span></div>
        </div>
      </v-col>
    </v-row>

    <ACHInfoModal :active="showACHInfoModal" v-on:modal="setShowACHInfoModal">
      <template v-slot:title>
        Useful ACH Information
      </template>
      <template v-slot:subtitle>
        Here’s how to find your <strong>ROUTING</strong> number AND your <strong>ACCOUNT</strong> number.
      </template>
      <template v-slot:content>
        <v-container fill-height>
          <v-row align="center" justify="center">
            <v-col class="sample-check d-flex justify-center">
              <v-img contain :src="require(`../../assets/sample_check.png`)" alt="Sample Check with ACH Information" :max-width="375"></v-img>
            </v-col>
            <v-col class="sample-app d-flex justify-center">
              <v-img contain :src="require(`../../assets/sample_app.png`)" alt="Sample App with ACH Information" :max-width="375"></v-img>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </ACHInfoModal>

    <ACHInfoModal :active="showACHAuthModal" v-on:modal="setShowACHAuthModal">
      <template v-slot:title>
        ACH Authorization Information
      </template>
      <template v-slot:content>
        <div class="content__text">
          I authorize <span class="blue-text">{{$store.state.merchantData.name}}</span> hereinafter named <span class="blue-text">COMPANY</span> to initiate an ACH/electronic debit to my account indicated below. I agree that future payments with <span class="blue-text">COMPANY</span> for purchase of ongoing products or services may be automatically debited from the account indicated below and future changes in my order for product or services may change the amount debited. 
        </div>
        <div class="content__text">
          I agree that ACH transactions I authorize comply with all applicable law. I understand that this authorization will remain in full force and effect until I notify <span class="blue-text">COMPANY</span> in writing that I wish to revoke this authorization. 
        </div>
        <div class="content__text">
          I understand that <span class="blue-text">COMPANY</span> requires at least 15 days prior notice in order to cancel this authorization.
        </div>
        <div class="content__text">
          Click <a class="ach-hint" v-on:click.prevent.stop="confirmAuthFromModal">here</a> to accept the Authorization and continue….
        </div>
      </template>
    </ACHInfoModal>

    <v-dialog
      content-class="summary-dialog"
      v-model="isSummaryOpenModel"
      maxWidth="500px"
      :overlay-opacity="0.28"
      origin="center right"
      transition="slide-x-reverse-transition"
    >
      <v-card class="summary-dialog--card">
        <v-toolbar
          light
          color="white"
          flat
          :rounded="false"
        >
          <v-col class="col-xs-6 d-flex px-0 summary-toolbar-button open">
              <span class="summary-text" @click="toggleSummary">Edit Payment</span><v-icon  @click="toggleSummary" class="summary-icon">{{ svgPath }}</v-icon>
          </v-col>

          <v-spacer></v-spacer>

          <v-col class="justify-end col-xs-6 d-flex px-0 summary-toolbar-button">
            <span class="summary-hide-button" @click="toggleSummary()">HIDE</span>
          </v-col>
        </v-toolbar>

        <div class="summary-dialog--card--summary">
          <div class="summary-dialog--card--summary--text">Summary</div>
          <div class="summary-dialog--card--summary--text-item" v-if="formFields.amount">Amount: <span class="summary-dialog--card--summary--text-item__black">{{totalAmountFormatted}}</span></div>
          <div class="summary-dialog--card--summary--text-item" v-if="formFields.customerName">Customer Name: <span class="summary-dialog--card--summary--text-item__blue">{{formFields.customerName}}</span></div>
          <div class="summary-dialog--card--summary--text-item" v-if="formFields.customFieldValue">Paid on Behalf of: <span class="summary-dialog--card--summary--text-item__blue">{{formFields.customFieldValue}}</span></div>
          <div class="summary-dialog--card--summary--text-item" v-if="formFields.accountNumber">Account #: <span class="summary-dialog--card--summary--text-item__blue">{{formFields.accountNumber}}</span></div>
          <div class="summary-dialog--card--summary--text-item" v-if="paymentMethod">Payment: <strong class="summary-dialog--card--summary--text-item__black">{{getPaymentType()}}</strong></div>
          <div class="summary-dialog--card--summary--text-item" v-if="paymentMethod === 'ach' && formFields.achRoutingNumber">Routing Number: <span class="summary-dialog--card--summary--text-item__blue">{{formFields.achRoutingNumber}}</span></div>
          <div class="summary-dialog--card--summary--text-item" v-if="paymentMethod === 'ach' && formFields.achAccountNumber">Account Number: <span class="summary-dialog--card--summary--text-item__blue">{{formFields.achAccountNumber}}</span></div>
          <div class="summary-dialog--card--summary--text-item" v-if="paymentMethod === 'ach' && formFields.achAccountType">Account Type: <span class="summary-dialog--card--summary--text-item__blue">{{formFields.achAccountType}}</span></div>
          <div class="summary-dialog--card--summary--text-item" v-if="paymentMethod === 'card' && formFields.cardNumber">Card Number: <span :class="$store.state.paymentSuccessful === false ? 'summary-dialog--card--summary--text-item__red' : 'summary-dialog--card--summary--text-item__blue'">{{formattedCard.slice(0, -4).replaceAll(/([0-9])/gm, '•')}}{{formattedCard.slice(-4)}}</span></div>
          <v-row v-if="formFields.billingAddress1 || formFields.billingAddress2 || formFields.billingPhone || formFields.billingCity || formFields.customerName || formFields.billingState || formFields.billingZip">
            <div class="billing-name">
              <div class="summary-dialog--card--summary--text-item">Billing:</div>
            </div>
            <div class="billing-details">
              <div class="summary-dialog--card--summary--text-item__blue billing-summary" v-if="formFields.customerName">{{formFields.customerName}}</div>
              <div class="summary-dialog--card--summary--text-item__blue billing-summary" v-if="formFields.billingAddress1">{{formFields.billingAddress1}}</div>
              <div class="summary-dialog--card--summary--text-item__blue billing-summary" v-if="formFields.billingAddress2">{{formFields.billingAddress2}}</div>
              <div class="summary-dialog--card--summary--text-item__blue billing-summary" v-if="formFields.billingCity">{{formFields.billingCity}}</div>
              <div class="summary-dialog--card--summary--text-item__blue billing-summary" v-if="formFields.billingState || formFields.billingZip">{{formFields.billingState}}{{formFields.billingState && formFields.billingZip ? ' ' : ''}}{{formFields.billingZip}}</div>
            </div>
          </v-row>

          <div class="summary-dialog--card--summary--text-item" v-if="formFields.billingEmail">Email: <span class="summary-dialog--card--summary--text-item__blue">{{formFields.billingEmail}}</span></div>

          <div class="summary-dialog--card--summary--text-header" v-if="renderPayNowButton && $store.state.merchantData.custom.summaryFooter">
            <template v-if="Array.isArray($store.state.merchantData.custom.summaryFooter)">
              <v-col v-for="(footer, index) in $store.state.merchantData.custom.summaryFooter" :key="index" class="ml-0 pl-0" cols="12" justify="center">
                <div v-html="footer"></div>
              </v-col>
            </template>
            <template v-else>
              <v-col class="ml-0 pl-0" cols="12" justify="center">
                <div v-html="$store.state.merchantData.custom.summaryFooter"></div>
              </v-col>
            </template>
          </div>

          <div v-if="renderPayNowButton" class="pay-now-container">
            <PaymentButton :disabled="paymentProcessing" :loading="$store.state.isProcessingPayment" :onClick="sendPayment">Pay Now</PaymentButton>
          </div>

          <div ref="scrollIntoView" class="loading-box mobile" v-if="$store.state.paymentProcessing || $store.state.paymentSuccessful !== null"> 
            <ProcessingBox :scrollIntoView="scrollIntoView" mobile :resetForm="() => {resetForm();toggleSummary()}" :setPanel="(val) => {setOpenPanel(val);toggleSummary()}" :paymentMethod="paymentMethod"></ProcessingBox>
          </div>

        </div>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  /* global AwsWafIntegration */

  import Accounting from 'accounting-js'
  import { mdiChevronUpCircle } from '@mdi/js'

  import { 
    formatCreditCard,
    formatExpirationDate,
    formatPhoneNumber,
    getCardType
  } from '../../utils/helperFunctions'

  import { feeSchedule, calculateFee } from '../../utils/cashDiscount'
  import AmountInput from '../../components/AmountInput/AmountInput'
  import ExpansionPanel from '../../components/ExpansionPanel/ExpansionPanel'
  import PaymentButton from '../../components/PaymentButton/PaymentButton'
  import ACHInfoModal from '../../components/ACHInfoModal/ACHInfoModal'
  import ACHAuthCheckbox from '../../components/ACHAuthCheckbox/ACHAuthCheckbox'
  import ProcessingBox from '../../components/ProcessingBox/ProcessingBox'
  import CountryField from '@/components/CountryDropdown/CountryDropdown'
  import StateField from '@/components/StateDropdown/StateDropdown'

  export default {
    name: 'Payment',

    components: {
      ACHAuthCheckbox,
      ACHInfoModal,
      AmountInput,
      CountryField,
      ExpansionPanel,
      PaymentButton,
      ProcessingBox,
      StateField
    },

    props: {
      isSummaryOpen: Boolean
    },

    data: function () {
      return {
        paymentMethod: 'card',

        openPanel: null,
        svgPath: mdiChevronUpCircle,
        currentDate: new Date(),

        cardNumberInputKey: 0,
        expirationInputKey: 0,

        isCaptchaVerified: false,

        isAcountInfoValid: false,
        isAmountValid: false,
        isCustomerInfoValid: false,
        isBillingInfoValid: false,
        isPaymentDetailsValid: false,
        hasCustomerInfoValidated: false,
        hasBillingInfoValidated: false,
        hasPaymentDetailsValidated: false,

        feeSchedule,

        formFields: this.defaultFields(),

        paymentSuccessful: null,
        paymentProcessing: false,

        rules: {
          required: value => !!value || 'Required',
          amountAboveZero: value => value && parseFloat(value) > 0 || 'Amount must be positive.',
          routingLength: value => value && value.length === 9 || 'Routing number much be nine digits.',
          expirationMinLength: value => value && value.length === 5 || 'Expiration should be in format MM/YY.',
          expirationInFuture: value => {
            if ((Number(value.slice(-2)) + 100 < this.currentDate.getYear() )
              || (Number(value.slice(-2)) + 100 === this.currentDate.getYear() && value.slice(0, 2) < this.currentDate.getMonth() + 1)) {
              return 'Card is expired.'
            }
            return true
          },
          isValidEmail: value => /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(value) || 'Invalid email format.',
        },

        showACHAuthModal: false,
        showACHInfoModal: false,
      }
    },

    created () {
      // Load AWS WAF challenge script
      let challengeScript = document.createElement('script')
      challengeScript.setAttribute('src', process.env.VUE_APP_CHALLENGE_SCRIPT_URL)
      challengeScript.defer = true
      document.head.appendChild(challengeScript)
    },

    mounted () {
      // wait for vuex to retrieve token/merchant information
      this.unsubscribe = this.$store.subscribe((mutation) => {
        if (mutation.type === 'setMerchantData') {
          this.parseStateDefaults()
          this.parseQueryParameters()
        }
      })
    },

    beforeDestroy() {
      this.unsubscribe()
    },

    computed: {
      isSummaryOpenModel: {
        get () { return this.isSummaryOpen },
        set (isSummaryOpen) { this.$emit('toggleSummary', isSummaryOpen) },
      },
      cardType () {
        let amex = /^3[47]\d{0,13}/
        let diners = /^3(?:0([0-5]|9)|[689]\d?)\d{0,11}/
        let discover = /^(?:6011|65\d{0,2}|64[4-9]\d?)\d{0,12}/
        let jcb = /^(?:35\d{0,2})\d{0,12}/
        let jcb15 = /^(?:2131|1800)\d{0,11}/
        let maestro = /^(?:5[0678]\d{0,2}|6304|67\d{0,2})\d{0,12}/
        let mastercard = /^(5[1-5]\d{0,2}|22[2-9]\d{0,1}|2[3-7]\d{0,2})\d{0,12}/
        let unionpay = /^62\d{0,14}/
        let visa = /^4\d{0,15}/

        if (amex.test(this.formFields.cardNumber)) {
          return 'amex'
        } else if (diners.test(this.formFields.cardNumber)) {
          return 'diners'
        } else if (discover.test(this.formFields.cardNumber)) {
          return 'discover'
        } else if (jcb.test(this.formFields.cardNumber)) {
          return 'jcb'
        } else if (jcb15.test(this.formFields.cardNumber)) {
          return 'jcb15'
        } else if (maestro.test(this.formFields.cardNumber)) {
          return 'maestro'
        } else if (mastercard.test(this.formFields.cardNumber)) {
          return 'mastercard'
        } else if (unionpay.test(this.formFields.cardNumber)) {
          return 'unionpay'
        } else if (visa.test(this.formFields.cardNumber)) {
          return 'visa'
        }
        return 'unknown'
      },

      totalAmountFormatted () {
        return Accounting.formatMoney(this.totalAmount)
      },

      totalAmount () {
        if (
          this.$store.state.merchantData.cashdiscount_schedule && this.paymentMethod === 'card'
        ) {
          return parseFloat(this.formFields.amount) + parseFloat(calculateFee(this.$store.state.merchantData, this.formFields.amount))
        } else {
          return this.formFields.amount
        }
      },
      totalFee () {
        if (
          this.$store.state.merchantData.cashdiscount_schedule && this.paymentMethod === 'card'
        ) {
          return calculateFee(this.$store.state.merchantData, this.formFields.amount)
        } else {
          return 0
        }
      },
      formattedCard () {
        return formatCreditCard(this.formFields.cardNumber)
      },
      formattedExpiration () {
        return formatExpirationDate(this.formFields.cardExpiration)
      },
      formattedPhone () {
        return formatPhoneNumber(this.formFields.billingPhone)
      },
      renderPayNowButton () {
        return this.isAmountValid &&
          this.isCustomerInfoValid &&
          this.isBillingInfoValid &&
          this.isPaymentDetailsValid &&
          (this.paymentMethod !== 'ach' || this.formFields.achAuthorized) &&
          !this.$store.state.paymentSuccessful
      },

      cashDiscountAmount (amount) {
        if (
          this.$store.state.merchantData.cashdiscount_schedule && this.paymentMethod === 'card'
        ) {
          return calculateFee(this.$store.state.merchantData, amount)
        }
        return amount
      }
    },

    methods: {
      defaultFields () {
        return {
          amount: '0.00',

          customerName: '',
          customField1: '',
          customField2: '',
          customField3: '',
          accountNumber: '',
          customFieldValue: '',

          cardNumber: '',
          cardExpiration: '',
          cardCVV: '',

          achRoutingNumber: '',
          achAccountNumber: '',
          achAccountType: '',
          achAuthorized: false,

          billingPhone: '',
          billingAddress1: '',
          billingAddress2: '',
          billingCity: '',
          billingState: '',
          billingZip: '',
          billingCountry: 'USA'
        }
      },

      // @TODO Need to have full field configuration for per token customization
      defaultFieldConfiguration () {
        return {
          amount: {
            type: 'number',
            field: 'transactions.total_amount',
            label: 'Amount',
            category: 'amount',
            defaultValue: '0.00',
            isHidden: false,
            isReadonly: false,
            isRequired: false
          },

          customerName: '',
          customField1: '',
          customField2: '',
          customField3: '',
          accountNumber: '',
          customFieldValue: '',

          cardNumber: '',
          cardExpiration: '',
          cardCVV: '',

          achRoutingNumber: '',
          achAccountNumber: '',
          achAccountType: '',
          achAuthorized: false,

          billingPhone: '',
          billingAddress1: '',
          billingAddress2: '',
          billingCity: '',
          billingState: '',
          billingZip: '',
          billingCountry: 'USA'
        }
      },

      parseStateDefaults () {
        // set payment type based on main terminal, then based on custom
        if (Object.keys(this.$store.state.merchantData).length && this.$store.state.merchantData.payment_types.includes('ach')) {
          this.paymentMethod = 'ach'
        }

        if (Object.keys(this.$store.state.merchantData).length && this.$store.state.merchantData.payment_types.includes('card')) {
          this.paymentMethod = 'card'
        }

        for (const field in this.formFields) {
          if (Object.prototype.hasOwnProperty.call(this.$store.state.merchantData.custom, 'fields') && Object.prototype.hasOwnProperty.call(this.$store.state.merchantData.custom.fields, field)) {
            if (field === 'amount') {
              this.formFields[field] = Accounting.toFixed(this.$store.state.merchantData.custom.fields[field].defaultValue / 100)
            } else {
              this.formFields[field] = this.$store.state.merchantData.custom.fields[field].defaultValue
            }
            // @TODO Handle other field customizations
          }
        }
      },

      /* Query parameter value overrides
         @TODO account for token permissions
      */
      parseQueryParameters () {
        const { address1, address2, amount, customername, city, email, id, phone, state, zip } = this.$route.query

        if (id) {
          this.formFields.accountNumber = id
        }
        if (amount) {
          this.formFields.amount = Accounting.toFixed(amount / 100)
        }
        if (customername) {
          this.formFields.customerName = customername
        }
        if (address1) {
          this.formFields.billingAddress1 = address1
        }
        if (address2) {
          this.formFields.billingAddress2 = address2
        }
        if (city) {
          this.formFields.billingCity = city
        }
        if (state) {
          this.formFields.billingState = state
        }
        if (zip) {
          this.formFields.billingZip = zip
        }
        if (email) {
          this.formFields.billingEmail = email
        }
        if (phone) {
          this.formFields.billingPhone = phone
        }
      },

      confirmAuthFromModal () {
        this.showACHAuthModal = false
        this.formFields.achAuthorized = true
      },

      getPaymentType () {
        if (this.paymentMethod !== 'ach' && getCardType(this.formFields.cardNumber) !== 'unknown') {
          return getCardType(this.formFields.cardNumber)
        }
        return this.paymentMethod.toUpperCase()
      },
      getCardMaxLength () {
        if (this.paymentMethod !== 'ach' && getCardType(this.formFields.cardNumber) !== 'unknown') {
          let maxLength
          switch (getCardType(this.formFields.cardNumber)) {
            case 'Visa':
            case 'Discover':
            case 'Mastercard':
            case 'JCB':
            case 'JCB15':
            case 'Diners Club':
              maxLength = 16 + 3
              break
            case 'American Express':
              maxLength = 15 + 2
              break
            case 'Maestro':
            case 'UnionPay': 
              maxLength = 19 + 3
          }
          return maxLength
        }
        return 19
      },
      cardMask () {
        if (this.cardType === 'amex') {
          return '#### ###### #####'
        } else if (this.cardType === 'diners') {
          return '#### ###### #####'
        } else if (this.cardType === 'discover') {
          return '#### #### #### ####'
        } else if (this.cardType === 'jcb') {
          return '#### #### #### ####'
        } else if (this.cardType === 'jcb15') {
          return '#### ###### #####'
        } else if (this.cardType === 'maestro') {
          return '#### #### #### ####'
        } else if (this.cardType === 'mastercard') {
          return '#### #### #### ####'
        } else if (this.cardType === 'unionpay') {
          return '#### #### #### ####'
        } else if (this.cardType === 'visa') {
          return '#### #### #### ####'
        }
        return '#### #### #### ####'
      },
      setMethod (method) {
        this.paymentMethod = method
      },
      setShowACHInfoModal (showACHInfoModal) {
        this.showACHInfoModal = showACHInfoModal
      },
      setOpenPanel (openPanel) {
        this.openPanel = openPanel
      },
      setShowACHAuthModal (showACHAuthModal) {
        this.showACHAuthModal = showACHAuthModal
      },
      toggleModal () {
        this.setShowACHInfoModal(!this.showACHInfoModal)
      },
      scrollIntoView() {
        const el = this.$refs.scrollIntoView;
        if (el) {
          el.scrollIntoView({behavior: 'smooth'});
        }
      },
      validateAll (doesNotSetNull) {
        if (!doesNotSetNull) {
          this.setOpenPanel(null)
        }
        this.$refs.customerInfo.validate()
        this.$refs.billingInfo.validate()
        this.$refs.paymentDetails.validate()

        this.hasCustomerInfoValidated = true
        this.hasBillingInfoValidated = true
        this.hasPaymentDetailsValidated = true
      },

      resetForm () {
        this.formFields = this.defaultFields()
        this.parseStateDefaults()
        this.parseQueryParameters()

        this.setOpenPanel('customer')
      },

      toggleSummary () {
        this.isSummaryOpenModel = !this.isSummaryOpenModel
      },

      async sendPayment () {
        this.validateAll()

        const wafToken = await AwsWafIntegration.getToken()

        if (this.isPaymentDetailsValid && this.isCustomerInfoValid && this.isBillingInfoValid) {
          this.$store.dispatch('sendPaymentRequest', {
            ...this.formFields,
            token: this.$store.state.token,
            wafToken: wafToken,
            paymentMethod: this.paymentMethod,
            isTokenize: true,
            amount: parseInt(Accounting.toFixed(this.formFields.amount * 100)),
            fee: (this.paymentMethod !== 'ach') ? parseInt(Accounting.toFixed(calculateFee(this.$store.state.merchantData, this.formFields.amount) * 100)) : 0,
            cardNumber: this.formFields.cardNumber.replace(/\D/g, '')
          })

          if (this.$vuetify.breakpoint.xs) {
            this.scrollIntoView()
          }
        }
      },
    }
  }
</script>
<style lang="less">
  @import './Payment.less';
</style>
