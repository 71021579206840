<template>
  <div class="spinner">
    <div class="spinner__line line-12">
      <div class="spinner__line__pill" style="opacity: 0.9;"></div>
    </div>
    <div class="spinner__line line-11">
      <div class="spinner__line__pill" style="opacity: 0.85;" ></div>
    </div>
    <div class="spinner__line line-10">
      <div class="spinner__line__pill" style="opacity: 0.75;"></div>
    </div>
    <div class="spinner__line line-9">
      <div class="spinner__line__pill" style="opacity: 0.65;"></div>
    </div>
    <div class="spinner__line line-8">
      <div class="spinner__line__pill" style="opacity: 0.55;"></div>
    </div>
    <div class="spinner__line line-7">
      <div class="spinner__line__pill" style="opacity: 0.5;"></div>
    </div>
    <div class="spinner__line line-6">
      <div class="spinner__line__pill" style="opacity: 0.45;"></div>
    </div>
    <div class="spinner__line line-5">
      <div class="spinner__line__pill" style="opacity: 0.4;"></div>
    </div>
    <div class="spinner__line line-4">
      <div class="spinner__line__pill" style="opacity: 0.35;"></div>
    </div>
    <div class="spinner__line line-3">
      <div class="spinner__line__pill" style="opacity: 0.3;"></div>
    </div>
    <div class="spinner__line line-2">
      <div class="spinner__line__pill" style="opacity: 0.25;"></div>
    </div>
    <div class="spinner__line line-1">
      <div class="spinner__line__pill" style="opacity: 0.2;"></div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "LoadingSpinner"
  }
</script>

<style lang="less" scoped>
  @import './LoadingSpinner.less';
</style>
