<template>
  <v-select
    :append-icon="icon"
    :disabled="disabled"
    :items="countries"
    :rules="rules"
    v-model="country"
  >
    <template v-slot:label>
      <span class="form-label">{{ label }}<span class="form-label__blue" v-if="required"> *</span></span>
    </template>
  </v-select>
</template>

<script>
export default {
  data: function () {
    return {
      countries: [
        {
          'value': 'AFG',
          'text': 'Afghanistan'
        },
        {
          'value': 'ALB',
          'text': 'Albania'
        },
        {
          'value': 'DZA',
          'text': 'Algeria'
        },
        {
          'value': 'ASM',
          'text': 'American Samoa'
        },
        {
          'value': 'AND',
          'text': 'Andorra'
        },
        {
          'value': 'AGO',
          'text': 'Angola'
        },
        {
          'value': 'AIA',
          'text': 'Anguilla'
        },
        {
          'value': 'ATA',
          'text': 'Antarctica'
        },
        {
          'value': 'ATG',
          'text': 'Antigua and Barbuda'
        },
        {
          'value': 'ARG',
          'text': 'Argentina'
        },
        {
          'value': 'ARM',
          'text': 'Armenia'
        },
        {
          'value': 'ABW',
          'text': 'Aruba'
        },
        {
          'value': 'AUS',
          'text': 'Australia'
        },
        {
          'value': 'AUT',
          'text': 'Austria'
        },
        {
          'value': 'AZE',
          'text': 'Azerbaijan'
        },
        {
          'value': 'BHS',
          'text': 'Bahamas'
        },
        {
          'value': 'BHR',
          'text': 'Bahrain'
        },
        {
          'value': 'BGD',
          'text': 'Bangladesh'
        },
        {
          'value': 'BRB',
          'text': 'Barbados'
        },
        {
          'value': 'BLR',
          'text': 'Belarus'
        },
        {
          'value': 'BEL',
          'text': 'Belgium'
        },
        {
          'value': 'BLZ',
          'text': 'Belize'
        },
        {
          'value': 'BEN',
          'text': 'Benin'
        },
        {
          'value': 'BMU',
          'text': 'Bermuda'
        },
        {
          'value': 'BTN',
          'text': 'Bhutan'
        },
        {
          'value': 'BOL',
          'text': 'Bolivia'
        },
        {
          'value': 'BES',
          'text': 'Bonaire, Sint Eustatius and Saba'
        },
        {
          'value': 'BIH',
          'text': 'Bosnia and Herzegovina'
        },
        {
          'value': 'BWA',
          'text': 'Botswana'
        },
        {
          'value': 'BVT',
          'text': 'Bouvet Island'
        },
        {
          'value': 'BRA',
          'text': 'Brazil'
        },
        {
          'value': 'IOT',
          'text': 'British Indian Ocean Territory'
        },
        {
          'value': 'VGB',
          'text': 'British Virgin Islands'
        },
        {
          'value': 'BRN',
          'text': 'Brunei Darussalam'
        },
        {
          'value': 'BGR',
          'text': 'Bulgaria'
        },
        {
          'value': 'BFA',
          'text': 'Burkina Faso'
        },
        {
          'value': 'BDI',
          'text': 'Burundi'
        },
        {
          'value': 'CPV',
          'text': 'Cabo Verde'
        },
        {
          'value': 'KHM',
          'text': 'Cambodia'
        },
        {
          'value': 'CMR',
          'text': 'Cameroon'
        },
        {
          'value': 'CAN',
          'text': 'Canada'
        },
        {
          'value': 'CYM',
          'text': 'Cayman Islands'
        },
        {
          'value': 'CAF',
          'text': 'Central African Republic'
        },
        {
          'value': 'TCD',
          'text': 'Chad'
        },
        {
          'value': 'CHL',
          'text': 'Chile'
        },
        {
          'value': 'CHN',
          'text': 'China'
        },
        {
          'value': 'HKG',
          'text': 'China, Hong Kong Special Administrative Region'
        },
        {
          'value': 'MAC',
          'text': 'China, Macao Special Administrative Region'
        },
        {
          'value': 'CXR',
          'text': 'Christmas Island'
        },
        {
          'value': 'CCK',
          'text': 'Cocos (Keeling) Islands'
        },
        {
          'value': 'COL',
          'text': 'Colombia'
        },
        {
          'value': 'COM',
          'text': 'Comoros'
        },
        {
          'value': 'COG',
          'text': 'Congo'
        },
        {
          'value': 'COK',
          'text': 'Cook Islands'
        },
        {
          'value': 'CRI',
          'text': 'Costa Rica'
        },
        {
          'value': 'HRV',
          'text': 'Croatia'
        },
        {
          'value': 'CUB',
          'text': 'Cuba'
        },
        {
          'value': 'CUW',
          'text': 'Curaçao'
        },
        {
          'value': 'CYP',
          'text': 'Cyprus'
        },
        {
          'value': 'CZE',
          'text': 'Czechia'
        },
        {
          'value': 'CIV',
          'text': 'Côte d\'Ivoire'
        },
        {
          'value': 'PRK',
          'text': 'Democratic People\'s Republic of Korea'
        },
        {
          'value': 'COD',
          'text': 'Democratic Republic of the Congo'
        },
        {
          'value': 'DNK',
          'text': 'Denmark'
        },
        {
          'value': 'DJI',
          'text': 'Djibouti'
        },
        {
          'value': 'DMA',
          'text': 'Dominica'
        },
        {
          'value': 'DOM',
          'text': 'Dominican Republic'
        },
        {
          'value': 'ECU',
          'text': 'Ecuador'
        },
        {
          'value': 'EGY',
          'text': 'Egypt'
        },
        {
          'value': 'SLV',
          'text': 'El Salvador'
        },
        {
          'value': 'GNQ',
          'text': 'Equatorial Guinea'
        },
        {
          'value': 'ERI',
          'text': 'Eritrea'
        },
        {
          'value': 'EST',
          'text': 'Estonia'
        },
        {
          'value': 'SWZ',
          'text': 'Eswatini'
        },
        {
          'value': 'ETH',
          'text': 'Ethiopia'
        },
        {
          'value': 'FLK',
          'text': 'Falkland Islands (Malvinas)'
        },
        {
          'value': 'FRO',
          'text': 'Faroe Islands'
        },
        {
          'value': 'FJI',
          'text': 'Fiji'
        },
        {
          'value': 'FIN',
          'text': 'Finland'
        },
        {
          'value': 'FRA',
          'text': 'France'
        },
        {
          'value': 'GUF',
          'text': 'French Guiana'
        },
        {
          'value': 'PYF',
          'text': 'French Polynesia'
        },
        {
          'value': 'ATF',
          'text': 'French Southern Territories'
        },
        {
          'value': 'GAB',
          'text': 'Gabon'
        },
        {
          'value': 'GMB',
          'text': 'Gambia'
        },
        {
          'value': 'GEO',
          'text': 'Georgia'
        },
        {
          'value': 'DEU',
          'text': 'Germany'
        },
        {
          'value': 'GHA',
          'text': 'Ghana'
        },
        {
          'value': 'GIB',
          'text': 'Gibraltar'
        },
        {
          'value': 'GRC',
          'text': 'Greece'
        },
        {
          'value': 'GRL',
          'text': 'Greenland'
        },
        {
          'value': 'GRD',
          'text': 'Grenada'
        },
        {
          'value': 'GLP',
          'text': 'Guadeloupe'
        },
        {
          'value': 'GUM',
          'text': 'Guam'
        },
        {
          'value': 'GTM',
          'text': 'Guatemala'
        },
        {
          'value': 'GGY',
          'text': 'Guernsey'
        },
        {
          'value': 'GIN',
          'text': 'Guinea'
        },
        {
          'value': 'GNB',
          'text': 'Guinea-Bissau'
        },
        {
          'value': 'GUY',
          'text': 'Guyana'
        },
        {
          'value': 'HTI',
          'text': 'Haiti'
        },
        {
          'value': 'HMD',
          'text': 'Heard Island and McDonald Islands'
        },
        {
          'value': 'VAT',
          'text': 'Holy See'
        },
        {
          'value': 'HND',
          'text': 'Honduras'
        },
        {
          'value': 'HUN',
          'text': 'Hungary'
        },
        {
          'value': 'ISL',
          'text': 'Iceland'
        },
        {
          'value': 'IND',
          'text': 'India'
        },
        {
          'value': 'IDN',
          'text': 'Indonesia'
        },
        {
          'value': 'IRQ',
          'text': 'Iraq'
        },
        {
          'value': 'IRL',
          'text': 'Ireland'
        },
        {
          'value': 'IMN',
          'text': 'Isle of Man'
        },
        {
          'value': 'ISR',
          'text': 'Israel'
        },
        {
          'value': 'ITA',
          'text': 'Italy'
        },
        {
          'value': 'JAM',
          'text': 'Jamaica'
        },
        {
          'value': 'JPN',
          'text': 'Japan'
        },
        {
          'value': 'JEY',
          'text': 'Jersey'
        },
        {
          'value': 'JOR',
          'text': 'Jordan'
        },
        {
          'value': 'KAZ',
          'text': 'Kazakhstan'
        },
        {
          'value': 'KEN',
          'text': 'Kenya'
        },
        {
          'value': 'KIR',
          'text': 'Kiribati'
        },
        {
          'value': 'KWT',
          'text': 'Kuwait'
        },
        {
          'value': 'KGZ',
          'text': 'Kyrgyzstan'
        },
        {
          'value': 'LAO',
          'text': 'Lao People\'s Democratic Republic'
        },
        {
          'value': 'LVA',
          'text': 'Latvia'
        },
        {
          'value': 'LBN',
          'text': 'Lebanon'
        },
        {
          'value': 'LSO',
          'text': 'Lesotho'
        },
        {
          'value': 'LBR',
          'text': 'Liberia'
        },
        {
          'value': 'LBY',
          'text': 'Libya'
        },
        {
          'value': 'LIE',
          'text': 'Liechtenstein'
        },
        {
          'value': 'LTU',
          'text': 'Lithuania'
        },
        {
          'value': 'LUX',
          'text': 'Luxembourg'
        },
        {
          'value': 'MDG',
          'text': 'Madagascar'
        },
        {
          'value': 'MWI',
          'text': 'Malawi'
        },
        {
          'value': 'MYS',
          'text': 'Malaysia'
        },
        {
          'value': 'MDV',
          'text': 'Maldives'
        },
        {
          'value': 'MLI',
          'text': 'Mali'
        },
        {
          'value': 'MLT',
          'text': 'Malta'
        },
        {
          'value': 'MHL',
          'text': 'Marshall Islands'
        },
        {
          'value': 'MTQ',
          'text': 'Martinique'
        },
        {
          'value': 'MRT',
          'text': 'Mauritania'
        },
        {
          'value': 'MUS',
          'text': 'Mauritius'
        },
        {
          'value': 'MYT',
          'text': 'Mayotte'
        },
        {
          'value': 'MEX',
          'text': 'Mexico'
        },
        {
          'value': 'FSM',
          'text': 'Micronesia'
        },
        {
          'value': 'MCO',
          'text': 'Monaco'
        },
        {
          'value': 'MNG',
          'text': 'Mongolia'
        },
        {
          'value': 'MNE',
          'text': 'Montenegro'
        },
        {
          'value': 'MSR',
          'text': 'Montserrat'
        },
        {
          'value': 'MAR',
          'text': 'Morocco'
        },
        {
          'value': 'MOZ',
          'text': 'Mozambique'
        },
        {
          'value': 'MMR',
          'text': 'Myanmar'
        },
        {
          'value': 'NAM',
          'text': 'Namibia'
        },
        {
          'value': 'NRU',
          'text': 'Nauru'
        },
        {
          'value': 'NPL',
          'text': 'Nepal'
        },
        {
          'value': 'NLD',
          'text': 'Netherlands'
        },
        {
          'value': 'NCL',
          'text': 'New Caledonia'
        },
        {
          'value': 'NZL',
          'text': 'New Zealand'
        },
        {
          'value': 'NIC',
          'text': 'Nicaragua'
        },
        {
          'value': 'NER',
          'text': 'Niger'
        },
        {
          'value': 'NGA',
          'text': 'Nigeria'
        },
        {
          'value': 'NIU',
          'text': 'Niue'
        },
        {
          'value': 'NFK',
          'text': 'Norfolk Island'
        },
        {
          'value': 'MNP',
          'text': 'Northern Mariana Islands'
        },
        {
          'value': 'NOR',
          'text': 'Norway'
        },
        {
          'value': 'OMN',
          'text': 'Oman'
        },
        {
          'value': 'PAK',
          'text': 'Pakistan'
        },
        {
          'value': 'PLW',
          'text': 'Palau'
        },
        {
          'value': 'PAN',
          'text': 'Panama'
        },
        {
          'value': 'PNG',
          'text': 'Papua New Guinea'
        },
        {
          'value': 'PRY',
          'text': 'Paraguay'
        },
        {
          'value': 'PER',
          'text': 'Peru'
        },
        {
          'value': 'PHL',
          'text': 'Philippines'
        },
        {
          'value': 'PCN',
          'text': 'Pitcairn'
        },
        {
          'value': 'POL',
          'text': 'Poland'
        },
        {
          'value': 'PRT',
          'text': 'Portugal'
        },
        {
          'value': 'PRI',
          'text': 'Puerto Rico'
        },
        {
          'value': 'QAT',
          'text': 'Qatar'
        },
        {
          'value': 'MDA',
          'text': 'Republic of Moldova'
        },
        {
          'value': 'ROU',
          'text': 'Romania'
        },
        {
          'value': 'RUS',
          'text': 'Russian Federation'
        },
        {
          'value': 'RWA',
          'text': 'Rwanda'
        },
        {
          'value': 'REU',
          'text': 'Réunion'
        },
        {
          'value': 'BLM',
          'text': 'Saint Barthélemy'
        },
        {
          'value': 'SHN',
          'text': 'Saint Helena'
        },
        {
          'value': 'KNA',
          'text': 'Saint Kitts and Nevis'
        },
        {
          'value': 'LCA',
          'text': 'Saint Lucia'
        },
        {
          'value': 'MAF',
          'text': 'Saint Martin'
        },
        {
          'value': 'SPM',
          'text': 'Saint Pierre and Miquelon'
        },
        {
          'value': 'VCT',
          'text': 'Saint Vincent and the Grenadines'
        },
        {
          'value': 'WSM',
          'text': 'Samoa'
        },
        {
          'value': 'SMR',
          'text': 'San Marino'
        },
        {
          'value': 'STP',
          'text': 'Sao Tome and Principe'
        },
        {
          'value': 'SAU',
          'text': 'Saudi Arabia'
        },
        {
          'value': 'SEN',
          'text': 'Senegal'
        },
        {
          'value': 'SRB',
          'text': 'Serbia'
        },
        {
          'value': 'SYC',
          'text': 'Seychelles'
        },
        {
          'value': 'SLE',
          'text': 'Sierra Leone'
        },
        {
          'value': 'SGP',
          'text': 'Singapore'
        },
        {
          'value': 'SXM',
          'text': 'Sint Maarten'
        },
        {
          'value': 'SVK',
          'text': 'Slovakia'
        },
        {
          'value': 'SVN',
          'text': 'Slovenia'
        },
        {
          'value': 'SLB',
          'text': 'Solomon Islands'
        },
        {
          'value': 'SOM',
          'text': 'Somalia'
        },
        {
          'value': 'ZAF',
          'text': 'South Africa'
        },
        {
          'value': 'SGS',
          'text': 'South Georgia and the South Sandwich Islands'
        },
        {
          'value': 'SSD',
          'text': 'South Sudan'
        },
        {
          'value': 'ESP',
          'text': 'Spain'
        },
        {
          'value': 'LKA',
          'text': 'Sri Lanka'
        },
        {
          'value': 'PSE',
          'text': 'State of Palestine'
        },
        {
          'value': 'SDN',
          'text': 'Sudan'
        },
        {
          'value': 'SUR',
          'text': 'Suriname'
        },
        {
          'value': 'SJM',
          'text': 'Svalbard and Jan Mayen Islands'
        },
        {
          'value': 'SWE',
          'text': 'Sweden'
        },
        {
          'value': 'CHE',
          'text': 'Switzerland'
        },
        {
          'value': 'SYR',
          'text': 'Syrian Arab Republic'
        },
        {
          'value': 'TJK',
          'text': 'Tajikistan'
        },
        {
          'value': 'THA',
          'text': 'Thailand'
        },
        {
          'value': 'MKD',
          'text': 'The former Yugoslav Republic of Macedonia'
        },
        {
          'value': 'TLS',
          'text': 'Timor-Leste'
        },
        {
          'value': 'TGO',
          'text': 'Togo'
        },
        {
          'value': 'TKL',
          'text': 'Tokelau'
        },
        {
          'value': 'TON',
          'text': 'Tonga'
        },
        {
          'value': 'TTO',
          'text': 'Trinidad and Tobago'
        },
        {
          'value': 'TUN',
          'text': 'Tunisia'
        },
        {
          'value': 'TUR',
          'text': 'Turkey'
        },
        {
          'value': 'TKM',
          'text': 'Turkmenistan'
        },
        {
          'value': 'TCA',
          'text': 'Turks and Caicos Islands'
        },
        {
          'value': 'TUV',
          'text': 'Tuvalu'
        },
        {
          'value': 'UGA',
          'text': 'Uganda'
        },
        {
          'value': 'UKR',
          'text': 'Ukraine'
        },
        {
          'value': 'ARE',
          'text': 'United Arab Emirates'
        },
        {
          'value': 'GBR',
          'text': 'United Kingdom of Great Britain and Northern Ireland'
        },
        {
          'value': 'TZA',
          'text': 'United Republic of Tanzania'
        },
        {
          'value': 'UMI',
          'text': 'United States Minor Outlying Islands'
        },
        {
          'value': 'VIR',
          'text': 'United States Virgin Islands'
        },
        {
          'value': 'USA',
          'text': 'United States'
        },
        {
          'value': 'URY',
          'text': 'Uruguay'
        },
        {
          'value': 'UZB',
          'text': 'Uzbekistan'
        },
        {
          'value': 'VUT',
          'text': 'Vanuatu'
        },
        {
          'value': 'VEN',
          'text': 'Venezuela'
        },
        {
          'value': 'VNM',
          'text': 'Viet Nam'
        },
        {
          'value': 'WLF',
          'text': 'Wallis and Futuna Islands'
        },
        {
          'value': 'ESH',
          'text': 'Western Sahara'
        },
        {
          'value': 'YEM',
          'text': 'Yemen'
        },
        {
          'value': 'ZMB',
          'text': 'Zambia'
        },
        {
          'value': 'ZWE',
          'text': 'Zimbabwe'
        },
        {
          'value': 'ALA',
          'text': 'Åland Islands'
        }
      ]
    }
  },

  props: {
    value: String,
    disabled: Boolean,
    icon: String,
    label: {
      type: String,
      default: 'Country'
    },
    required: {
      type: Boolean,
      default: false
    },
    rules: Array
  },

  computed: {
    country: {
      get () {
        return this.value
      },
      set (country) {
        this.$emit('input', country)
      }
    }
  }
}
</script>
