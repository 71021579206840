<template>
  <div class="amount-input" v-show="!hidden">
    <vuetify-money
      v-model="amount"
      backgroundColor=""
      class="amount-text"
      :disabled="disabled"
      hideDetails
      :options="options"
      outlined
      :properties="properties"
      :rules="rules"
      :readonly="readonly"
      ref="amountInput"
      valueWhenIsEmpty="0.00"
    ></vuetify-money>
  </div>
</template>

<script>
  export default {
    name: 'AmountInput',

    data: () => ({
      options: {
        locale: 'en-US',
        prefix: '$',
        suffix: 'Amount',
        length: 9,
        precision: 2
      },
      properties: {
        ref: 'amountInput',
      }
    }),

    props: {
      disabled: {
        default: false,
        type: [Boolean, String],
      },
      hidden: {
        default: false,
        type: [Boolean, String],
      },
      label: {
        default: 'Amount',
        type: String,
      },
      readonly: {
        default: false,
        type: [Boolean, String],
      },
      rules: Array,
      value: [Number, String]
    },

    computed: {
      amount: {
        get () {
          return this.value
        },
        set (amount) {
          this.$emit('input', amount)
        }
      }
    },

    mounted() {
      this.options.suffix = this.label
    }
  }
</script>

<style lang="less">
  @import "./AmountInput.less";
</style>
