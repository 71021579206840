import Vue from 'vue'
import App from './App.vue'

import router from './router'
import store from './store'

import api from './plugins/api'
import vuetify from './plugins/vuetify'
import './plugins/vuetify-money'

import { VueMaskDirective } from 'v-mask'

Vue.config.productionTip = false
require('dotenv').config()

Vue.directive('mask', VueMaskDirective);

Vue.use(api)

new Vue({
  router,
  store,
  vuetify,

  beforeCreated () {
    // reset error
    // @TODO remove persist state, persist any pieces to localState?
    store.commit('setError', false)

    // setup domain
    let domain = 'paynetworx'
    if (window.location.host.toLowerCase().includes('blueyonder')) {
      domain = 'blueyonder'
    }
    if (window.location.host.toLowerCase().includes('atmone')) {
      domain = 'atmone'
    }
    if (window.location.host.toLowerCase().includes('okdmv')) {
      domain = 'okdmv'
    }
    store.commit('setDomain', domain)
  },

  async created () {
    try {
      this.$store.commit('resetState')
      await this.$store.dispatch('getTokenData', this.$route.query.token)
    } catch (error) {
      store.commit('setError', true)
    }
  },
  render: h => h(App)
}).$mount('#app')
