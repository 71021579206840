import Vue from 'vue'
import Vuex from 'vuex'
import VueCookie from 'vue-cookie'
import createPersistedState from 'vuex-persistedstate'

import actions from './actions'
import mutations from './mutations'
import state from './state'

Vue.use(Vuex)
Vue.use(VueCookie)

export default new Vuex.Store({
  actions,
  mutations,
  state,
  plugins: [createPersistedState()]
})
