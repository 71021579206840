<template>
  <v-app class="hpp-app">
    <Header v-if="typeof $store.state.merchantData === 'object' && Object.keys($store.state.merchantData).length" @showSummary="toggleSummary" />

    <v-main>
      <Error v-if="$store.state.error" />
      <Payment v-else :isSummaryOpen="isSummaryOpen" @toggleSummary="toggleSummary" />
    </v-main>

    <v-container fluid class="pl-10 pr-10 ma-0 top-header">
      <v-row v-if="typeof $store.state.merchantData === 'object' && Object.keys($store.state.merchantData).length && $store.state.merchantData.custom.hasOwnProperty('footer') && $store.state.merchantData.custom.footer" align="center" justify="center">
        <template v-if="Array.isArray($store.state.merchantData.custom.footer)">
          <v-col v-for="(footer, index) in $store.state.merchantData.custom.footer" :key="index" align="center" class="ma-0 pa-0" cols="12" justify="center">
            <div v-html="footer"></div>
          </v-col>
        </template>
        <template v-else>
          <v-col align="center" class="ma-0 pa-0" cols="12" justify="center">
            <div v-html="$store.state.merchantData.custom.footer"></div>
          </v-col>
        </template>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import Error from './pages/Error/Error'
import Header from './components/Header/Header'
import Payment from './pages/Payment/Payment'

export default {
  name: 'HPP',

  components: {
    Error,
    Header,
    Payment,
  },

  data: () => ({
    isSummaryOpen: false,
  }),

  created () {
    if (this.$store.state.domain === 'blueyonder' || this.$store.state.merchantData.parent === 'blueyonder') {
      document.querySelector("link[rel*='icon']").href = '/blueyondercorp-favicon-ok.png'
    }
  },

  methods: {
    toggleSummary () {
      this.isSummaryOpen = !this.isSummaryOpen
    },
  }
};

</script>
<style lang="less" scoped>
  @import './assets/less/main.less';
</style>
