<template>
  <div class="checkbox-container">
    <v-checkbox class="large" v-model="isChecked" color="var(--blueText)">
      <template v-slot:label>
        <div large__text-container>
          <div class="large__text-container__text">
            <strong>ACH</strong> Authorization required
          </div>
          <div class="large__text-container__ach-hint"><span @click.stop.prevent="click">Read more...?</span></div>
        </div>
      </template>
    </v-checkbox>
  </div>
</template>

<script>
  export default {
    name: 'ACHAuthCheckbox',
    props: {
      value: Boolean
    },
    computed: {
      isChecked: {
        get () {
          return this.value
        },
        set (isChecked) {
          this.$emit('input', isChecked)
        }
      }
    }
  }
</script>

<style lang="less" scoped>
  @import './ACHAuthCheckbox.less';
</style>
