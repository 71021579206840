export default {
  resetState (state) {
    state.error = false
    state.merchantData = {}
    state.token = ''
    state.isProcessingPayment = false
    state.paymentSuccessful = null
    state.paymentDetails = null
  },

  setError (state, error) {
    state.error = error
  },

  setDomain (state, domain) {
    state.domain = domain
  },

  setMerchantData (state, merchantData) {
    state.merchantData = {...merchantData}
  },

  setToken (state, token) {
    state.token = token
  },

  getFieldData(state) {
    if (typeof state.merchantData.fields === 'undefined') {
      return {}
    }
    return state.merchantData.fields
  },
  setPaymentSuccessful (state, paymentSuccessful) {
    state.paymentSuccessful = paymentSuccessful
  },
  setIsProcessingPayment (state, isProcessingPayment) {
    state.isProcessingPayment = isProcessingPayment
  },
  setPaymentDetails (state, paymentDetails) {
    state.paymentDetails = paymentDetails
  },
}
