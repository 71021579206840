<template>
  <v-select
    v-if="!textfield"
    :append-icon="icon"
    :disabled="disabled"
    :items="states"
    :rules="rules"
    v-model="state"
  >
    <template v-slot:label>
      <span class="form-label">{{ label }}<span class="form-label__blue" v-if="required"> *</span></span>
    </template>
  </v-select>

  <v-text-field
    v-else
    :append-icon="icon"
    :disabled="disabled"
    :rules="rules"
    v-model="state"
  >
    <template v-slot:label>
      <span class="form-label">{{ label }}<span class="form-label__blue" v-if="required"> *</span></span>
    </template>
  </v-text-field>
</template>

<script>
export default {
  data: function () {
    return {
      states: [
        {text: 'Alabama', value: 'AL'},
        {text: 'Alaska', value: 'AK'},
        {text: 'Arizona', value: 'AZ'},
        {text: 'Arkansas', value: 'AR'},
        {text: 'California', value: 'CA'},
        {text: 'Colorado', value: 'CO'},
        {text: 'Connecticut', value: 'CT'},
        {text: 'Delaware', value: 'DE'},
        {text: 'District of Columbia', value: 'DC'},
        {text: 'Florida', value: 'FL'},
        {text: 'Georgia', value: 'GA'},
        {text: 'Hawaii', value: 'HI'},
        {text: 'Idaho', value: 'ID'},
        {text: 'Illinois', value: 'IL'},
        {text: 'Indiana', value: 'IN'},
        {text: 'Iowa', value: 'IA'},
        {text: 'Kansa', value: 'KS'},
        {text: 'Kentucky', value: 'KY'},
        {text: 'Lousiana', value: 'LA'},
        {text: 'Maine', value: 'ME'},
        {text: 'Maryland', value: 'MD'},
        {text: 'Massachusetts', value: 'MA'},
        {text: 'Michigan', value: 'MI'},
        {text: 'Minnesota', value: 'MN'},
        {text: 'Mississippi', value: 'MS'},
        {text: 'Missouri', value: 'MO'},
        {text: 'Montana', value: 'MT'},
        {text: 'Nebraska', value: 'NE'},
        {text: 'Nevada', value: 'NV'},
        {text: 'New Hampshire', value: 'NH'},
        {text: 'New Jersey', value: 'NJ'},
        {text: 'New Mexico', value: 'NM'},
        {text: 'New York', value: 'NY'},
        {text: 'North Carolina', value: 'NC'},
        {text: 'North Dakota', value: 'ND'},
        {text: 'Ohio', value: 'OH'},
        {text: 'Oklahoma', value: 'OK'},
        {text: 'Oregon', value: 'OR'},
        {text: 'Pennsylvania', value: 'PA'},
        {text: 'Rhode Island', value: 'RI'},
        {text: 'South Carolina', value: 'SC'},
        {text: 'South Dakota', value: 'SD'},
        {text: 'Tennessee', value: 'TN'},
        {text: 'Texas', value: 'TX'},
        {text: 'Utah', value: 'UT'},
        {text: 'Vermont', value: 'VT'},
        {text: 'Virginia', value: 'VA'},
        {text: 'Washington', value: 'WA'},
        {text: 'West Virginia', value: 'WV'},
        {text: 'Wisconsin', value: 'WI'},
        {text: 'Wyoming', value: 'WY'}
      ]
    }
  },

  props: {
    value: String,
    disabled: Boolean,
    icon: String,
    label: {
      type: String,
      default: 'State'
    },
    required: {
      type: Boolean,
      default: false
    },
    rules: Array,
    textfield: Boolean
  },

  computed: {
    state: {
      get () {
        return this.value
      },
      set (state) {
        this.$emit('input', state)
      }
    }
  }
}
</script>
