// import Config from '../../config/index'
import Vue from 'vue'

export default {
  async getTokenData ({ commit }, token) {
    commit('setToken', token)

    try {
      const data = await Vue.prototype.$api({
        method: 'get',
        url: '/merchants/' + token,
      })

      if (!data.merchant || data.error) {
        const error = (data && data.error) || data.status
        throw new Error(error)
      }

      commit('setMerchantData', data.merchant)
    } catch (error) {
      console.error('getTokenData Error:', error)
      commit('setError', true)
    }
  },

  async sendPaymentRequest ({ commit }, body) {
    try {
      commit('setIsProcessingPayment', true)

      const data = await Vue.prototype.$api({
        method: 'post',
        url: '/payment/' + body.token,
        wafToken: body.wafToken,
        data: body
      })

      const response = {}
      if (data.response.ResponseText === 'APPROVED') {
        response.transactionDetails = data.response.Transaction
        response.responseMessage = 'Payment Approved'
      } else if (data.response.ResponseText === 'PENDING') {
        response.transactionDetails = data.response.Transaction
        response.responseMessage = 'Scheduled Payment Approved'
      } else if (data.response.ResponseText === 'CHECK ACCEPTED') {
        response.transactionDetails = data.response.Transaction
        response.responseMessage = 'Payment Accepted'
      } else {
        let error = data.response.ResponseText
        if (data.error) {
          console.error(data)
          throw new Error(data.response.statusCode + ' ' + data.response.error)
        }

        response.transactionDetails = {}
        response.isResponseError = true
        response.responseMessage = error
        response.responseMessageDetails = ''
    
        if (error === 'DECLINED') {
          response.responseMessageDetails = 'Please verify card data including the expiration and CVV'
        }
    
        if (data.response.Transaction && data.response.Transaction.error_messages) {
          let messages = JSON.parse(data.response.Transaction.error_messages)
    
          // show detailed error as long as it isn't the generic 'do not honor'
          if (messages.length > 0 && messages[0] !== 'TRANS DENIED DO NOT HONOR' && messages[0] !== error) {
            response.responseMessageDetails = messages.join()
          }
        }
      }

      if (!response.transactionDetails || response.error) {
        const error = (response && response.error) || response.status
        throw new Error(error)
      }

      commit('setPaymentSuccessful', true)
      commit('setPaymentDetails', response)
    } catch (error) {
      console.error('Error:', error)
      commit('setPaymentSuccessful', false)
    } finally {
      commit('setIsProcessingPayment', false)
    }
  } 
}
