import Accounting from 'accounting-js'

export const feeSchedule = {
  '200percent': {
    10000000000: {
      type: 'percentage',
      amount: 2.00
    }
  },
  '250percent': {
    10000000000: {
      type: 'percentage',
      amount: 2.50
    }
  },
  '275percent': {
    10000000000: {
      type: 'percentage',
      amount: 2.75
    }
  },
  '300percent': {
    10000000000: {
      type: 'percentage',
      amount: 3.00
    }
  },
  '375percent': {
    10000000000: {
      type: 'percentage',
      amount: 3.75
    }
  },
  '400percent': {
    10000000000: {
      type: 'percentage',
      amount: 4.00
    }
  },
  blueyonder395: {
    10000000000: {
      type: 'percentage',
      amount: 3.95
    }
  },
  blueyonder350: {
    10000000000: {
      type: 'percentage',
      amount: 3.5
    }
  },
  okdmv: {
    10000000000: {
      type: 'percentage',
      amount: 2.25,
      feeAppend: 200,
      feePrepend: 200
    }
  }
}
export const calculateFee = (terminal, amount) => {
  // validate schedule
  if (
    !terminal.is_cashdiscount
  ) {
    return 0.00
  }

  // validate amount
  if (isNaN(amount) || amount === null || Number(amount) === 0) {
    return 0.00
  }

  amount = amount * 100

  let feeScheduleKey = terminal.cashdiscount_schedule
  let localFeeSchedule = Object.keys(feeSchedule[feeScheduleKey])
  let key = localFeeSchedule.find(value => Number(value) > Number(amount))
  let formula = feeSchedule[feeScheduleKey][key]
  if (formula.type === 'rate') {
    return Accounting.toFixed((formula.amount + amount) / 100)
  } else {
    if (formula.feePrepend) {
      amount += formula.feePrepend
    }

    amount = amount * (formula.amount / 100)

    if (formula.feeAppend) {
      amount += formula.feeAppend
    }

    return Accounting.toFixed(amount / 100)
  }
}
