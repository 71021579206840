<template>
  <div>
    <v-btn
      block
      class="payment-button"
      :color="disabled ? 'rgba(183, 181, 183, 0.46)' : (color || '#83dc25')"
      depressed
      :disabled="disabled"
      :loading="loading"
      @click="onClick"
      tile
      x-large
    >
      <slot></slot>
    </v-btn>
  </div>
</template>

<script>
  export default {
    name: 'PaymentButton',
    props: {
      color: String,
      disabled: Boolean,
      loading: Boolean,
      onClick: Function,
    },
  }
</script>

<style lang="less" scoped>
  @import './PaymentButton.less';
</style>
