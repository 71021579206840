<template>
  <div class="text-center">
    <v-dialog
      v-model="isOpen"
      width="90%"
      max-width="940px"
      @click:outside="toggleIsOpen"
      overlay-opacity="0.55"
      content-class="ach-dialog rounded-xl"
    >
      <v-card>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="var(--blueText)"
            text
            @click="toggleIsOpen"
          >
            <span class="close-btn">Close</span> <span class="close-btn--x">+</span>
          </v-btn>
        </v-card-actions>
        <v-card-title>
          <div class="card-title__ach-info" :class="{mobile: $vuetify.breakpoint.xs}">
            <slot name="title"></slot>
          </div>
        </v-card-title>

        <v-card-text class="card-content__subtitle">
          <slot name="subtitle"></slot>
        </v-card-text>
        <v-card-text class="card-content__text">
          <slot name="content"></slot>
        </v-card-text>
        <v-divider></v-divider>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    name: 'ACHInfoModal',
    props: {
      active: Boolean,
      submit: Function,
    },
    data () {
      return {
        isOpen: this.active
      }
    },
    methods: {
      toggleIsOpen () {
        this.isOpen = !this.isOpen
        this.$emit('modal', this.isOpen);
      }
    },
    watch: {
      active (newVal) {
        this.isOpen = newVal
      }
    }
  }
</script>

<style lang="less" scoped>
  @import './ACHInfoModal.less';
</style>
