export default {
  environment: process.env.NODE_ENV,
  error: false,
  domain: '',

  token: '',
  merchantData: {},

  isProcessingPayment: false,
  paymentDetails: null,
  paymentSuccessful: null,
}
