export default {
  // eslint-disable-next-line no-unused-vars
  install(Vue) {
    Vue.prototype.$api = async function (opts) {
      let url = process.env.VUE_APP_API_URL + opts.url

      let requestOptions = {
        credentials: 'same-origin',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        method: opts.method || 'GET',
        mode: 'cors',
        referrerPolicy: 'no-referrer',
      }

      // add waf token information
      if (Object.prototype.hasOwnProperty.call(opts, 'wafToken') && opts.wafToken) {
        requestOptions.headers['x-aws-waf-token'] = opts.wafToken
        requestOptions.headers['aws-waf-token'] = opts.wafToken
      }

      // add GET parameters
      if (Object.prototype.hasOwnProperty.call(opts, 'params')) {
        Object.keys(opts.params).forEach(key => url.searchParams.append(key, opts.params[key]))
      }

      // add POST body
      if (Object.prototype.hasOwnProperty.call(opts, 'data')) {
        requestOptions.body = JSON.stringify(opts.data)
      }

      // perform request
      let result = await fetch(url, requestOptions)
        .then(response => {
          if (response.status >= 200 && response.status <= 299) {
            return response.json()
          } else {
            if (response.status === 401) {
              console.error('Unauthorized Request')
            }
            if (response.status === 403) {
              console.error('Forbidden Request')
            }
          }
          throw Error('ERROR' + response.statusText)
        })
        .then(data => {
          return data
        })
        .catch(error => {
          console.error('FETCH ERROR')
          console.error(error)
          throw error
        })

      return result
    }
  }
}
