<template>
  <div class="processing-box" :class="{mobile: mobile}">
    <div v-if="$store.state.isProcessingPayment" class="loading" :class="{mobile}">
      <div class="loading__spinner" :class="{mobile}">
        <LoadingSpinner></LoadingSpinner>
      </div>
      <div class="loading__text">Please wait while we process <strong>your</strong> transaction…</div>
    </div>
    <div class="processing-success" v-if="!$store.state.isProcessingPayment && $store.state.paymentSuccessful">
      <div class="processing-success__thanks">
        THANK YOU!
      </div>
      <div class="processing-success__received">
        Your payment has been received
      </div>
      <!-- <div @click="resetForm" class="processing-success__back">BACK</div> -->
    </div>
    <div class="processing-failed" v-if="!$store.state.isProcessingPayment && $store.state.paymentSuccessful === false">
      <div class="processing-failed__declined">
        PAYMENT DECLINED!
      </div>
      <div class="processing-failed__text">
        Oops! Something's gone wrong.
      </div>
      <div @click="setPanel('payment')" class="processing-failed__try-again">TRY ANOTHER {{paymentMethod === 'card' ? 'CARD' : 'ACCOUNT'}}</div>
    </div>
  </div>
</template>

<script>
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner.vue'
  export default {
    name: 'ProcessingBox',
    mounted () {
      if (this.scrollIntoView) {
        this.scrollIntoView()
      }
    },
    props: {
      setPanel: Function,
      resetForm: Function,
      mobile: Boolean,
      scrollIntoView: Function,
      paymentMethod: String
    },
    components: {
      LoadingSpinner
    }
  }
</script>

<style lang="less" scoped>
  @import './ProcessingBox.less';
</style>
