export const formatPhoneNumber = (number) => {
  number = number.replace(/[^0-9]+/gm, '')
  let formatted
  if (number.length >= 3) {
    formatted = '(' + number.substr(0, 3) + ') ' + number.substr(3, 3)
    if (number.length >= 6) {
      formatted = formatted + '-' + number.substr(6, 4)
    }
  }
  return formatted
}

export const formatExpirationDate = (date) => {
  if (date.length >= 2) {
    date = date.substr(0, 2) + '/' + date.substr(2, 2)
  }
  return date
}

export const getCardType = (pan) => {
  let amex = /^3[47]\d{0,13}/
  let diners = /^3(?:0([0-5]|9)|[689]\d?)\d{0,11}/
  let discover = /^(?:6011|65\d{0,2}|64[4-9]\d?)\d{0,12}/
  let jcb = /^(?:35\d{0,2})\d{0,12}/
  let jcb15 = /^(?:2131|1800)\d{0,11}/
  let maestro = /^(?:5[0678]\d{0,2}|6304|67\d{0,2})\d{0,12}/
  let mastercard = /^(5[1-5]\d{0,2}|22[2-9]\d{0,1}|2[3-7]\d{0,2})\d{0,12}/
  let unionpay = /^62\d{0,14}/
  let visa = /^4\d{0,15}/
  
  if (amex.test(pan)) {
    return 'American Express'
  } else if (diners.test(pan)) {
    return 'Diners Club'
  } else if (discover.test(pan)) {
    return 'Discover'
  } else if (jcb.test(pan)) {
    return 'JCB'
  } else if (jcb15.test(pan)) {
    return 'JCB15'
  } else if (maestro.test(pan)) {
    return 'Maestro'
  } else if (mastercard.test(pan)) {
    return 'Mastercard'
  } else if (unionpay.test(pan)) {
    return 'UnionPay'
  } else if (visa.test(pan)) {
    return 'Visa'
  }
  return 'unknown'
}

export const formatCreditCard = (value) => {
  var v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '')
  var parts = []
  let matches = v.match(/\d{4,16}/g);
  let match = matches && matches[0] || ''
  switch(getCardType(value)) {
    case 'Visa':
    case 'Discover':
    case 'Mastercard':
    case 'JCB':
    case 'JCB15':
      for (let i=0; i < match.length; i+=4) {
        parts.push(match.substring(i, i + 4))
      }
      break
    case 'Diners Club':
      if (value.length === 16) {
        for (let i=0; i < match.length; i += 4) {
          parts.push(match.substring(i, i + 4))
        }
      } else {
        matches = v.match(/\d{4,14}/g);
        match = matches && matches[0] || ''
        for (let i=0; i < match.length; i += i === 4 ? 6 : 4) {
          parts.push(match.substring(i, i+ i === 4 ? 6 : 4))
        }
      }
      break
    case 'American Express':
      matches = v.match(/\d{4,15}/g)
      match = matches && matches[0] || ''
      for (let i=0; i < match.length; i += (i === 0 ? 4 : (i === 4 ? 6 : 5))) {
        parts.push(match.substring(i, i + (i === 0 ? 4 : (i === 4 ? 6 : 5))))
      }
      break
    case 'Maestro':
      matches = v.match(/\d{4,19}/g)
      if (value.length > 15) {
        for (let i=0, len=match.length; i<len; i+=4) {
          parts.push(match.substring(i, i+4))
        }
      } else if (value.length === 15) {
        for (let i=0; i < match.length; i += (i === 0 ? 4 : (i === 4 ? 6 : 5))) {
          parts.push(match.substring(i, i + (i === 0 ? 4 : (i === 4 ? 6 : 5))))
        }
      } else {
        for (let i=0; i < match.length; i += i === 2 ? 5 : 4) {
          parts.push(match.substring(i, i += i === 2 ? 5 : 4))
        }
      }
      break
    case 'UnionPay':
      if (length < 16) {
        for (let i=0, len=match.length; i<len; i+=4) {
          parts.push(match.substring(i, i+4))
        }
      } else {
        matches = v.match(/\d{4,19}/g)
        match = matches && matches[0] || ''
        for (let i=0; i < match.length; i += (i === 0 ? 6 : 13)) {
          parts.push(match.substring(i, i + (i === 0 ? 6 : 13)))
        }
      }
      break
    default:
      matches = v.match(/\d{4,19}/g)
      match = matches && matches[0] || ''
      for (let i=0; i < match.length; i+=4) {
        parts.push(match.substring(i, i + 4))
      }
  }
  if (parts.length) {
    return parts.join(' ')
  } else {
    return value
  }
}
