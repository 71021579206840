<template>
  <v-container fluid class="pl-10 pr-10 ma-0 top-header">
    <v-row v-if="$store.state.merchantData.custom.header" align="center" justify="center">
      <template v-if="Array.isArray($store.state.merchantData.custom.header)">
        <v-col v-for="(header, index) in $store.state.merchantData.custom.header" :key="index" align="center" class="ma-0 pa-0" cols="12" justify="center">
          <div v-html="header"></div>
        </v-col>
      </template>
      <template v-else>
        <v-col align="center" class="ma-0 pa-0" cols="12" justify="center">
          <div v-html="$store.state.merchantData.custom.header"></div>
        </v-col>
      </template>
    </v-row>

    <v-row align="end" justify="space-between" class="pa-0">
      <v-col v-if="$store.state.merchantData.logo" cols="5" xs="6">
        <v-img
          :alt="$store.state.merchantData.name + ' Logo'"
          class="shrink mr-2"
          contain
          :src="imageUrl + '/' + $store.state.merchantData.logo"
          transition="scale-transition"
          max-height="110"
          position="bottom left"
        />
      </v-col>

      <v-col v-else cols="5" xs="6">
        <div class="merchant-name">{{$store.state.merchantData.name}}</div>
      </v-col>

      <v-col cols="0" xs="6" @click="showSummary" class="align-self-center summary-button d-sm-none">
        <span class="summary-text">Your Summary</span><v-icon class="summary-icon">{{ svgPath }}</v-icon>
      </v-col>

      <v-col cols="7" xs="12" class="pa-0 header--mandatory-text">
        Fields with <i class="header--blue-text">blue asterisk*</i> are mandatory.
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mdiChevronUpCircle } from '@mdi/js'

  export default {
    name: 'Header',  

    data: () => ({
      imageUrl: process.env.VUE_APP_IMAGE_URL,
      svgPath: mdiChevronUpCircle,
    }),

    methods: {
      showSummary () {
        this.$emit('showSummary', true)
      },
    },

  }
</script>

<style lang="less" scoped>
  @import './Header.less';
</style>
